<!-- 品牌管理 -->
<template>
  <a-button style="margin-bottom: 10px" type="primary" @click="newCreate()">新建品牌</a-button>
  <a-table
    bordered
    rowKey="logicId"
    size="middle"
    :columns="columns"
    :data-source="source"
    :pagination="pagination"
    :loading="pagination.loading"
  >
    <template #operation="{ record }">
      <a-button type="primary" size="small" @click="editCates(record)">修改</a-button>
    </template>
  </a-table>
  <a-modal
    :title="modifyModal.title"
    v-model:visible="modifyModal.visible"
    :confirm-loading="modifyModal.loading"
    @ok="handleOk"
    @cancel="handlerCancel"
    width="500px"
  >
    <a-spin v-if="modifyModal.status" />
    <a-form
      v-else
      ref="formRef"
      :model="modifyModal.data"
      :rules="modifyModal.rules"
      :label-col="{ span: 0 }"
      :wrapper-col="{ span: 19 }"
    >
      <a-form-item label="品牌名称" name="name">
        <a-input v-model:value="modifyModal.data.name" />
      </a-form-item>
      <div
        class="brand"
        v-for="( itemS, index ) in modifyModal.starGoodsBrandAndTypes"
        :key="index"
      >
        <a-form-item label="优惠星球" name="starId">
          <a-select
            v-model:value="modifyModal.starGoodsBrandAndTypes[index].starId"
            style="width: 120px"
            placeholder="请选择"
            @change="change2(index)"
          >
            <a-select-option
              v-for="(item, i) in modifyModal.selects1"
              :key="i"
              :value="item.logicId"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="二级类目" name="goodsTypeTwo">
          <a-select
            v-model:value="modifyModal.starGoodsBrandAndTypes[index].goodsTypeTwo"
            style="width: 120px"
            placeholder="请选择"
            @change="change3(index)"
          >
            <a-select-option
              v-for="(item, i) in modifyModal.starGoodsBrandAndTypes[index].goodsTypeTwos"
              :key="i"
              :value="item.logicId"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="三级类目" name="goodsTypeThree">
          <a-select
            v-model:value="modifyModal.starGoodsBrandAndTypes[index].goodsTypeThree"
            style="width: 120px"
            placeholder="请选择"
          >
            <a-select-option
              v-for="(item, i) in modifyModal.starGoodsBrandAndTypes[index].goodsTypeThrees"
              :key="i"
              :value="item.logicId"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <a-form-item>
        <a-button type="link" @click="newCreateItem()">新增关联三级类目</a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted } from 'vue'
import { saveOrUpdate, page, detail } from '@/api/brand'
import { query as querys } from '@/api/public'
import { getStarList } from '@/api/planet'
import { message } from 'ant-design-vue'
export default {
  setup() {
    const { formRef, columns, pagination, source, modifyModal, editCates, handlerCancel, handleOk, newCreate, change2, change3, newCreateItem } = useCategory2Manage()
    return { formRef, columns, pagination, source, modifyModal, editCates, handlerCancel, handleOk, newCreate, change2, change3, newCreateItem }
  },
};
function useCategory2Manage() {
  // 表格标题配置
  const columns = [
    { title: 'ID', dataIndex: 'logicId', width: '15%' },
    { title: '标题', dataIndex: 'name', width: '65%' },
    { title: '操作', dataIndex: 'operation', width: '20%', slots: { customRender: 'operation' } },
  ]
  const rulesMod = { // 修改规则
    name: { required: true, message: '请输入类目名称', trigger: 'blur' }
  }
  const source = ref([]) // 显示的表格数据
  const formRef = ref();
  // ， ， 
  /**
   * title: modal标题
   * visible: modal是否出现
   * loading：modal点击提交按钮是否加载
   * status：modal出现之后是否加载
   * form: 表单数据对象
   * data：网络请求的数据
   * rules: 表单提交轨辙
   * selects: 选择数据
   */
  const modifyModal = ref({
    title: '', visible: false, loading: false, status: true, form: {}, data: {}, rules: rulesMod,
    selects1: [],
    selects2: [],
    selects3: [],
    name: '',
    starGoodsBrandAndTypes: []
  })

  const pagination = ref({ // 分页配置
    loading: true,
    position: 'bottom',
    total: 0, // 条数
    defaultPageSize: 20, // 默认分页条数
    pageSizeOptions: ['5', '10', '15', '20', '30'],
    showSizeChanger: true, // 是否可以改变 pageSize
    showQuickJumper: true, // 是否可以快速跳转至某页
    showTotal: total => `共 ${total} 条`,
    hideOnSinglePage: false, // 只有一页时是否隐藏分页器
    onChange: (selectedRowKeys, selectedRows) => {
      pagination.value.loading = true;
      getList(selectedRowKeys, selectedRows)
    }
  })
  // 加载修改页面
  const loadingMod = async (logicId) => {
    const { data, status } = await detail({ id: logicId })

    if (status == '200') {
      const level2 = await querys({ level: '2' })
      const level3 = await querys({ level: '3' })
      await querys({ level: '3' })
      console.log("start");
      console.log(JSON.stringify(data, null, 2))
      const { starGoodsBrandAndTypes, logicId, name } = data
      modifyModal.value.data = { name, logicId }
      if (starGoodsBrandAndTypes && Array.isArray(starGoodsBrandAndTypes)) {
        for (let i = 0; i < starGoodsBrandAndTypes.length; i++) {
          let source = {}
          source = starGoodsBrandAndTypes[i]
          const { goodsTypeThree, goodsTypeTwo, starId, brandId, logicId } = source
          source = { goodsTypeThree, goodsTypeTwo, starId, logicId, brandId, goodsTypeThrees: [], goodsTypeTwos: [] }
          if (goodsTypeTwo) {
            let { data } = level2
            data = data || []
            source.goodsTypeTwos = data.filter((item) => {
              if (item.starId === starId) {
                return true
              }
            })
          }
          if (goodsTypeThree) {
            let { data } = level3
            data = data || []
            source.goodsTypeThrees = data.filter((item) => {
              if (item.parentId === goodsTypeTwo) {
                return true
              }
            })
            console.log('source.goodsTypeThrees', source.goodsTypeThrees)
            console.log('source.goodsTypeThrees', level3)
          }
          modifyModal.value.starGoodsBrandAndTypes.push(source)
        }
      }
      // modifyModal.value.data = { logicId, name, ...source } // 将要修改的数据加载到modal
      modifyModal.value.status = false //停止modal加载状态
      console.log(modifyModal.value.starGoodsBrandAndTypes)
      return
    }
    message.warning('系统繁忙');
  }
  // 触发修改页面
  const editCates = (row) => {
    modifyModal.value.status = true
    modifyModal.value.title = '修改品牌'
    modifyModal.value.visible = true // 打开modal
    modifyModal.value.starGoodsBrandAndTypes = []
    const { logicId } = row
    loadingMod(logicId)
  }
  // 触发新建页面
  const newCreate = () => {
    modifyModal.value.title = '新建品牌'
    modifyModal.value.visible = true // 打开modal
    modifyModal.value.data = {} // 初始化modal重的表格数据
    modifyModal.value.status = false // 停止modal加载状态
    modifyModal.value.loading = false // 提交按钮加载状态
    modifyModal.value.starGoodsBrandAndTypes = []
  }

  const handlerCancel = async () => {
    modifyModal.value.status = true
    modifyModal.value.loading = false // 提交按钮加载状态
  }
  const handleOk = () => {
    formRef.value.validate().then(() => {
      const exce = async () => {
        modifyModal.value.loading = true
        const { logicId, name } = modifyModal.value.data
        const starGoodsBrandAndTypes = modifyModal.value.starGoodsBrandAndTypes
        const { status, msg } = await saveOrUpdate({ name, logicId, starGoodsBrandAndTypes })
        if (status == '200') {
          message.success(msg)
          modifyModal.value.loading = false
          modifyModal.value.visible = false
          getList()
          return
        }
        modifyModal.value.loading = false
        message.error(msg)
      }
      exce()
    })
      .catch(error => {
        console.log('error', error);
      });

  }
  // 获取分页数据
  async function getList(pageNo = "1", pageSize = "20") {
    const { data, status } = await page({ pageNo, pageSize })
    if (status == '200') {
      const { total, dataOnThisPage } = data
      pagination.value.total = total || 0
      source.value = dataOnThisPage || []
    }
    pagination.value.loading = false;

  }
  async function change2(index) {
    const { data } = await querys({ level: '2' })
    const starId = modifyModal.value.starGoodsBrandAndTypes[index].starId
    modifyModal.value.starGoodsBrandAndTypes[index].goodsTypeTwo = ''
    modifyModal.value.starGoodsBrandAndTypes[index].goodsTypeThree = ''
    modifyModal.value.starGoodsBrandAndTypes[index].goodsTypeTwos = data.filter((item) => {
      if (item.starId === starId) {
        return true
      }
    })
    modifyModal.value.starGoodsBrandAndTypes[index].goodsTypeThrees = []

  }
  async function change3(index) {
    const { data } = await querys({ level: '3' })
    const goodsTypeTwo = modifyModal.value.starGoodsBrandAndTypes[index].goodsTypeTwo
    modifyModal.value.starGoodsBrandAndTypes[index].goodsTypeThree = ''
    modifyModal.value.starGoodsBrandAndTypes[index].goodsTypeThrees = data.filter((item) => {
      if (item.parentId === goodsTypeTwo) {
        return true
      }
    })
  }
  async function newCreateItem() {
    modifyModal.value.starGoodsBrandAndTypes.push({ goodsTypeThree: '', goodsTypeTwo: '', starId: '', goodsTypeThrees: [], goodsTypeTwos: [] })
  }
  onMounted(getList)
  onMounted(async () => {
    // 星球
    const star = await getStarList({ pageNum: 1, pageSize: 1000 })
    // // 二级类目
    // const two = await pagesc({pageNo:"1", pageSize:"1000", level: "2"})
    // //三级类目
    // const three = await pagesc({pageNo:"1", pageSize:"1000", level: "3"})
    modifyModal.value.selects1 = star.data.dataOnThisPage || []
    // modifyModal.value.selects2 =  two.data.dataOnThisPage || []
    // modifyModal.value.selects3 =  three.data.dataOnThisPage || []
  })
  return { formRef, columns, pagination, source, modifyModal, editCates, handlerCancel, handleOk, newCreate, change2, change3, newCreateItem }
}
</script>

<style>
.brand {
  display: flex;
}
</style>
